<template>
  <div class="home">
    <!-- 头部 -->
    <div class="home_header">
      <!-- Split button -->
      <nav class="navbar navbar-default">
        <div class="container-fluid">
          <!-- Brand and toggle get grouped for better mobile display -->
          <div class="navbar-header">
            <button
              type="button"
              class="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
              aria-expanded="false"
              style="margin-top: 40px"
            >
              <span class="sr-only">Toggle navigation</span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>
            <!-- <img src="../assets/img/logo.png" alt="" /> -->
          </div>

          <!-- Collect the nav links, forms, and other content for toggling -->
          <div
            class="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
          >
            <ul class="nav navbar-nav navbar-right" style="margin-top: 25px">
              <li><a href="#">首页</a></li>
              <li class="dropdown">
                <a>产品介绍 </a>
              </li>
            </ul>
          </div>
          <!-- /.navbar-collapse -->
        </div>
        <!-- /.container-fluid -->
      </nav>
    </div>
    <!-- 背景 -->
    <div class="containers"></div>
    <div class="swiper-slide-title">
      <h1 style="margin-top: 200px">连接 - 共生未来</h1>
      <div class="itemtitle">
        <span></span>
        <p>致力于更高效和可持续的世界</p>
        <span></span>
      </div>
      <h4>COMMITTED TO A MORE EFFICIENT AND SUSTAINABLE WORLD</h4>
    </div>
    <!-- 内容 -->
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-xs-12 cont_title">
          <div class="titbidate">
            <h3 class="bigData-title abouttitls">致力于更高效和可持续的世界</h3>
            <p class="bigdasmellti englistti">
              COMMITTED TO A MORE EFFICIENT AND SUSTAINABLE WORLD
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-xs-12 col-sm-12 lefft_sec">
          <!-- 标题 -->
          <div class="Data_title">
            <h1>粮策APP</h1>
            <span
              class="bigdasmellti"
              style="font-family: 'PingFang SC' !important"
              >OUR APPLICATIONG SOFTWARE</span
            >
            <p>
              粮策App是提供和粮食贸易相关的产品和服务的平台。通过网上平台将粮食供应商和粮食加工企业直接联系在一起，同时提供物流运输、仓储、供应链、金融等第三方服务，以及大数据分析等IT服务。业务的价值在于减少了传统粮食贸易的中间环节，将粮食相关的服务商整合到一个平台上，有效降低买卖双方的信息不对称程度，提高粮食行业运转效率。
            </p>
            <div data-v-7446ba82="" class="ewmtitpc" style="text-align: center">
              <img src="../assets/img/liangce.jpg" style="width: 40%" />
            </div>
          </div>
        </div>
        <div class="col-md-6 col-xs-12 col-sm-12" style="text-align: center">
          <div class="right_sec">
            <div class="img">
              <img
                src="../assets/img/index.png"
                alt=""
                style="width: 60%; height: 60%"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-xs-12 col-sm-12" style="visibility: visible">
          <img
            src="../assets/img/imp.76c7427.png"
            alt=""
            style="max-width: 100%"
          />
        </div>
        <div class="col-md-6 col-xs-12 col-sm-12 rightabout">
          <h2 data-v-7446ba82="" class="bigData-title abouttitls">关于我们</h2>
          <span
            data-v-7446ba82=""
            class="bigdasmellti"
            style="font-family: 'PingFang SC' !important"
            >ABOUT US</span
          >
          <p data-v-7446ba82="" style="margin: 1rem auto; text-align: justify">
            粮策数据是一家高科技服务公司，目前，公司依托于粮油贸易产业深挖产业需求，运用互联网、商业智能大数据、云计算等技术赋能企业，帮助其变革营销模式，提高产业链运营效率。
          </p>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="home_footer">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-3 log_sec">
            <div data-v-5c546452="" class="logo">
              <!-- <img src="../assets/img/logo.png" alt="logo图" /> -->
            </div>
            <div class="titbodi">© 2022 粮策数据</div>
          </div>
          <div class="pagelist col-xs-12 col-sm-12 col-md-3">
            <h3 class="titles">商务合作</h3>
            <p class="contentti">
              粮策数据希望与更多优秀的企业达成长效机制上的合作。如果您需要商务合作，或有其他的建议，请联系我们。
            </p>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 pagelist_two">
            <h3 class="titles">联系方式</h3>
            <p class="cont">山东省德州市德城区尚城国际粮策数据</p>
            <p class="cont">17662442888</p>
            <p class="cont">info@liangce.cc</p>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 pagelist">
            <h3 class="titles">公司位置</h3>
          </div>
        </div>
        <div class="'row'">
          <div class="cl-md-12" style="text-align: center; margin-top: 50px">
            鲁ICP备20008434号-4
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "HomeView",
  components: {},
};
</script>
<style lang="less">
li:hover {
  border: 1px solid #fff;
}
.home {
  width: 100%;
  height: 100%;
  position: relative;
  // 头部
  .home_header {
    width: 100%;
    height: 110px;
    transition: all 0.6s ease;
    position: absolute;
    z-index: 999;
    background: rgba(0, 0, 0, 0.3);
    .navbar-default {
      width: 80%;
      height: 110px;
      margin: 0 auto;
      background: none;
      border: none;
      .container-fluid {
        line-height: 110px;
        img {
          width: 175px;
          height: 45px;
        }
        .navbar-nav {
          li {
            a {
              color: #fff;
            }
          }
        }
      }
    }
  }
  // 背景
  .containers {
    width: 100%;
    height: 700px;
    background: url(../assets/img/beijing.png) no-repeat center center;
    background-size: cover;
    line-height: 700px;
  }
  .swiper-slide-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 700px;
    color: #fff;
    background: rgba(51, 51, 51, 0.534);
    text-align: center;
    line-height: 80px;
  }
  // 内容
  .container {
    .row {
      .cont_title {
        text-align: center;
        margin-top: 6rem;
        .bigData-title {
          font-size: 5.3rem;
          font-family: PingFang SC;
          font-weight: 700;
          line-height: 8.49rem;
          letter-spacing: 0.7rem;
          color: #333;
        }
        p {
          color: #333;
          line-height: 3rem;
          font-weight: lighter;
          // width: 49rem;
          // margin: 5rem 0 2rem;
          margin-top: 5rem;
          font-size: 2rem;
        }
      }
    }
    .row {
      .lefft_sec {
        .Data_title {
          h1 {
            font-size: 50px;
            font-family: PingFang SC;
            font-weight: 700;
            line-height: 8.49rem;
            letter-spacing: 0.7rem;
            color: #333;
            text-align: left;
          }
          .bigdasmellti {
            font-size: 2rem;
            font-weight: 700;
            line-height: 3.18rem;
            color: #333;
            font-weight: lighter;
          }
          p {
            color: #333;
            line-height: 3rem;
            font-weight: lighter;
            width: 100%;
            margin: 5rem 0 2rem;
            font-size: 2rem;
            font-family: PingFang SC;
          }
        }
      }
    }
    .row {
      .rightabout {
        h2 {
          font-size: 5.3rem;
          font-family: PingFang SC;
          font-weight: 700;
          line-height: 8.49rem;
          letter-spacing: 0.7rem;
          color: #333;
          text-align: center;
        }
        .bigdasmellti {
          font-size: 2rem;
          font-weight: 700;
          line-height: 3.18rem;
          color: #333;
          font-weight: lighter;
          text-align: center;
          display: block;
        }
        p {
          color: #333;
          line-height: 3rem;
          font-weight: lighter;
          // width: 49rem;
          margin: 5rem 0 2rem;
          font-size: 2rem;
          font-family: PingFang SC;
        }
      }
    }
  }
  // 底部
  .home_footer {
    margin-top: 60px;
    width: 100%;
    height: 100%;
    color: #fff;
    background: #474747;
    overflow: hidden;
    // text-align: center;
    .container {
      .row {
        .log_sec {
          .logo {
            width: 21rem;
            margin: auto;
            padding: 2rem 2.6rem 2rem 0;
            img {
              width: 100%;
            }
          }
          .titbodi {
            text-align: center;
            font-size: 1.7rem;
            font-family: Source Sans Pro;
            font-weight: 400;
            line-height: 3.18rem;
            color: #fff;
          }
        }
        .pagelist {
          .titles {
            font-size: 2.12rem;
            font-family: PingFang SC;
            font-weight: 700;
            line-height: 3.18rem;
            color: #fff;
          }
          .contentti {
            font-size: 1.9rem;
            opacity: 0.5;
            // width: 31rem;
            letter-spacing: 0.1rem;
          }
        }
        .pagelist_two {
          .titles {
            font-size: 2.12rem;
            font-family: PingFang SC;
            font-weight: 700;
            line-height: 3.18rem;
            color: #fff;
          }
          .cont {
            overflow: hidden;
            font-size: 1.8rem;
            // width: 32rem;
          }
        }
      }
    }
  }
}
// 媒体查询
@media screen and (min-width: 1024px) {
  .home {
    .containers {
      width: 100%;
      height: 700px;
      // position: absolute;
      // top: 0;
      z-index: -999;
      background: url(../assets/img/beijing.png) no-repeat center center;
      background-size: cover;
      line-height: 700px;
    }
    .swiper-slide-title {
      h1 {
        margin-top: 13%;
        font-size: 5.31rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 13.26rem;
        display: inline-block;
        color: #fff;
        letter-spacing: 19px;
      }
      .itemtitle {
        font-size: 2.52rem;
        h4 {
          font-size: 1.46rem;
          font-family: Source Han Serif CN;
          font-weight: 200;
          line-height: 3.71rem;
          color: #fff;
        }
      }
    }
  }
}
@media screen and (min-width: 640px) and (max-width: 1023px) {
  .home {
    .containers {
      width: 100%;
      height: 400px;
      // position: absolute;
      // top: 0;
      z-index: -999;
      background: url(../assets/img/beijing.png) no-repeat center center;
      background-size: cover;
      line-height: 700px;
    }
    .swiper-slide-title {
      width: 100%;
      height: 400px;
    }
    .container {
      .row {
        .cont_title {
          text-align: center;
          margin-top: 6rem;
          .bigData-title {
            font-size: 3rem;
            font-family: PingFang SC;
            font-weight: 700;
            line-height: 8.49rem;
            letter-spacing: 0.7rem;
            color: #333;
          }
        }
      }
    }
    .home_footer {
      width: 100%;
      height: 100%;
      color: #fff;
      background: #474747;
      overflow: hidden;
      // text-align: center;
      .container {
        .row {
          .log_sec {
            text-align: center;
            .logo {
              width: 21rem;
              margin: auto;
              padding: 2rem 2.6rem 2rem 0;
              display: none;
              img {
                width: 100%;
              }
            }
            .titbodi {
              // text-align: center;
              font-size: 2rem;
              font-family: Source Sans Pro;
              font-weight: 400;
              line-height: 3.18rem;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 320px) and (max-width: 639px) {
  .home {
    .containers {
      width: 100%;
      height: 700px;
      // position: absolute;
      // top: 0;
      z-index: -999;
      background: url(../assets/img/beijing.png) no-repeat center center;
      background-size: cover;
      line-height: 700px;
    }
    .swiper-slide-title {
      width: 100%;
      height: 700px;
    }
    .container {
      .row {
        .cont_title {
          text-align: center;
          margin-top: 6rem;
          .bigData-title {
            font-size: 3.6rem;
            font-family: PingFang SC;
            font-weight: 700;
            line-height: 6.49rem;
            // letter-spacing: 0.7rem;
            color: #333;
          }
        }
      }
    }
  }
}
</style>
